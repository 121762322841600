<template>
    <div>
        <div class="info-header">
            <b-button variant="light"
                      v-if="typeof setup!='undefined'"
                      v-b-popover.hover.top="typeof setup!='undefined' ? $t('settings.workflow.help.'+setup.id) : '-'"
            >
                <font-awesome-icon icon="info"/>
            </b-button>

            <b-button v-for="(error,index) in errors"
                      :key="index"
                      class="ml-2"
                      variant="danger"
                      v-b-popover.hover.top="error"
            >
                <font-awesome-icon icon="exclamation"/>
            </b-button>
        </div>
        <div class="card-devices">
            <div class="header">
                <h3>Customer to Offerings convertor</h3>
            </div>
        </div>
    </div>
</template>

<script>
import bus from './../event-bus';
import {mapGetters} from "vuex";
import Conditions from "@/modals/offering/workflow-options/Conditions";
import Condition from "@/components/Condition";

export default {
    components: {Conditions, Condition},
    props: {},
    data() {
        return {
            dataId: null,
            dataNode: null,
            setup: {},
            errors: [],
        }
    },
    mounted() {
        this.$nextTick(() => {
            const id = this.$el.parentElement.parentElement.id;
            this.dataId = id;
            bus.$emit('getData', id);
        });

        bus.$on('SendData', (data) => {
            if (data.id === this.dataId) {
                this.dataNode = data.dataNode;
                this.setup = data.dataNode.setup;
                bus.$emit('refreshNode', this.dataId);
            }
        });

        bus.$on('SendError', (data) => {
            if ('node-' + data.id === this.dataId) {
                this.errors.push(data.error)
            }
        });

        bus.$on('ClearError', (data) => {
            this.errors = []
        });
    },
    methods: {
        ...mapGetters('Workflow', ['getTimeTypes', 'getTimeAttributes', 'getTimeDirections', 'getConditions', 'getDateValues']),
        save() {
            bus.$emit('saveData', this.dataId, this.setup);
        },
        show() {
            this.$root.$children[0].openModal('workflow-selection-modal', this.setup)
        }
    },
    watch: {
        setup: function (val) {
            if (val) {
                this.save()
            }
        },
    }
}
</script>